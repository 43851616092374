(function() {
  "use strict";

  /**
   * Arrows component of the Carousel.
   * They're triggered by js-carousel-backward/forward classes.
   */
  function Arrows() {}

  /**
   * Handle carousel with directional buttons.
   * @protected
   */
  Arrows.prototype._run = function( event ) {
    var target = event.target;
    var container = this._container;
    var amount = (this._list.children.length - 1);
    var index;

    while( target != container ) {

      if ( target.classList.contains("js-carousel-forward") ) {

        // Set index to 0 if current index == slide amount or increase it.
        index = this._currentIndex == amount ? 0 : (this._currentIndex + 1);

        this._handle( index, "forward" );

        return;
      }

      if ( target.classList.contains("js-carousel-backward") ) {

        // Set index to last if current index == 0 or decrease it.
        index = this._currentIndex == 0 ? amount : (this._currentIndex - 1);

        this._handle( index, "backward" );

        return;
      }

      target = target.parentElement;
    }
  };

  /**
   * Buttons component of the Carousel.
   * They're triggered by the container with js-carousel-buttons class.
   * @class
   * @augments Arrows
   */
  function Buttons() {}

  Buttons.prototype = Object.create( Arrows.prototype );
  Buttons.prototype.constructor = Buttons;

  /**
   * Handle carousel with radio buttons.
   * @protected
   */
  Buttons.prototype._run = function( event ) {
    var target = event.target;
    var container = this._container;
    var index;

    while( target != container ) {

      if ( !target.parentElement.classList.contains("js-carousel-buttons") ) {

        target = target.parentElement;

        continue;
      }

      index = this._getIndex( target );

      if ( index > this._currentIndex ) {
        this._handle( index, "forward" );
      }

      if ( index < this._currentIndex ) {
        this._handle( index, "backward" );
      }

      return;
    }
  };

  /**
   * Select active button.
   * @protected
   */
  Buttons.prototype._check = function( index ) {
    var buttons = this._container.querySelectorAll(".js-carousel-buttons > *");

    buttons[this._currentIndex].classList.remove("is-active");

    buttons[index].classList.add("is-active");
  };

  /**
   * Get button index.
   * @protected
   */
  Buttons.prototype._getIndex = function( elem ) {
    var parent = elem.parentElement;
    var child = parent.firstElementChild;
    var index = 0;

    while ( true ) {

      if ( child == elem || child.nextElementSibling == null ) {
        break;
      }

      child = child.nextElementSibling;

      index++;
    }

    return index;
  };

  /**
   * Carousel.
   * @augments Buttons
   * @param {HTMLElement} [options.container] - Container element.
   * @param {string} options.mode - Mode. Accepted "fading" or "slide".
   * @version 5.0.0
   * @author Daur Gamisonia <daurgam@gmail.com>
   */
  window.Carousel = function Carousel( options ) {

    // Container element.
    if ( options.container ) {
      this._container = options.container;
    } else {
      this._container = document.querySelector(".js-carousel-toggle");
    }

    if ( options.onHandled ) {
        this._onHandled = options.onHandled;
    }

    // Set the mode.
    this._mode = options.mode.toLowerCase() || "fading";

    if ( this._mode != "fading" && this._mode != "slide" ) {
      throw new Error( "Unknown parameter " + "'" + this._mode + "'" );
    }

    // Slide list.
    this._list = this._container.querySelector(".js-carousel-slides");

    // Block with button & arrows.
    this._control = this._container.querySelector(".js-carousel-control") ||                    this._container;

    // Current slide index.
    this._currentIndex = 0;

    this._text = options.text;

    // Status.
    this._isPending = false;
  };

  Carousel.prototype = Object.create( Buttons.prototype );
  Carousel.prototype.constructor = Carousel;

  /**
   * Handle slideshow.
   * @public
   * @param {number} interval - Interval in milliseconds.
   */
  Carousel.prototype.runSlideshow = function( interval ) {
    var control = this._control;

    var timeoutID = setTimeout(function run() {
      var visibility = document.visibilityState;

      if ( visibility != "visible" || visibility == "prerender" ) {
        return;
      }

      if ( this._currentIndex == this._list.children.length - 1 ) {

        this._handle( 0, "forward" );

      } else {

        this._handle( this._currentIndex + 1, "forward" );
      }

      timeoutID = setTimeout( run.bind(this), interval );

    }.bind(this), interval );

    // Toggle slideshow depending on the hover state.
    control.onmouseenter = function() {
      clearTimeout( timeoutID );
    };

    control.onmouseleave = function() {
      this.runSlideshow( interval );
    }.bind(this);
  };

  /**
   * Handle slide switching.
   * @protected
   */
  Carousel.prototype._handle = function( index, direction ) {

    if ( this._isPending == true || index == this._currentIndex ) {
      return;
    }

    if ( this._mode == "fading" ) {
      this._fade( index );
    }

    if ( this._mode == "slide" ) {
      this._slide( index, direction );
    }

    this._check( index );

    if (this._onHandled) this._onHandled( index );
  };

  /**
   * Slide mode.
   * @protected
   * @param {number} index - Next slide index.
   * @param {string} direction - Animation direction.
   */
  Carousel.prototype._slide = function( index, direction ) {
    var list = this._list;
    var width = this._list.children[this._currentIndex].offsetWidth;
    var currentSlide = this._list.children[this._currentIndex];
    var nextSlide = this._list.children[index];
    var duration = this._getDuration( this._list );

    this._isPending = true;

    nextSlide.classList.add("is-active");

    if ( direction == "forward" ) {

      nextSlide.style.left = width + "px";

      list.style.transform = "translateX(" + (-width) + "px)";
    }

    if ( direction == "backward" ) {

      nextSlide.style.right = width + "px";

      list.style.transform = "translateX(" + width + "px)";
    }

    setTimeout(function() {

      list.style.transition = "none";

      list.style.transform = "";

      currentSlide.classList.remove("is-active");

      nextSlide.style.cssText = "";

      setTimeout(function() {

        list.style.transition = "";

        this._currentIndex = index;

        this._isPending = false;

      }.bind(this), 20 );

    }.bind(this), duration );
  };

  /**
   * Fading mode.
   * @protected
   * @param {number} index - Next slide index.
   */
  Carousel.prototype._fade = function( index ) {
    var list = this._list;
    var currentSlide = this._list.children[this._currentIndex];
    var nextSlide = this._list.children[index];
    // Fix bug in Firefox by adding 30 ms to the result.
    var duration = this._getDuration( this._list ) + 30;

    this._isPending = true;

    list.style.opacity = "0";

    setTimeout(function() {

      currentSlide.classList.remove("is-active");

      nextSlide.classList.add("is-active");

      list.style.opacity = "1";

      this._currentIndex = index;

      this._isPending = false;

    }.bind(this), duration );
  };

  /**
   * Get transition duration.
   * @protected
   */
  Carousel.prototype._getDuration = function( elem ) {
    var duration = parseFloat( getComputedStyle( elem ).transitionDuration );

    // Return duration in MS.
    return duration * 1000;
  };

  /**
   * Listen buttons.
   * @public
   */
  Carousel.prototype.listen = function() {
    var container = this._container;

    container.addEventListener("click", Arrows.prototype._run.bind(this));

    container.addEventListener("click", Buttons.prototype._run.bind(this));
  };
})();

var carouselNode = document.querySelector(".js-banner-toggle")
if (carouselNode) {
    var carousel = new Carousel({
        container: carouselNode,
        mode: "fading"
    });
    carousel.listen();
    carousel.runSlideshow( 5000 );
}
