(function() {
  "use strict";

  /**
   * Expandable navigation menu that turn off or on depending on the viewport width.
   * @augment Collapse
   * @param {string} options.trigger - Button selector.
   * @param {string} options.target - Expandable element selector. Multiple elements are supported.
   * @param {number} options.maxWidth - Threshold in pixels when the menu should be reset.
   * @version 3.0.0
   * @author Daur Gamisonia <daurgam@gmail.com>
   */
  function MobileMenu( options ) {
    this._trigger = document.querySelector( options.trigger );
    this._targets = document.querySelectorAll( options.target );
    this._maxWidth = options.maxWidth;
  }

  MobileMenu.prototype = Object.create( Collapse.prototype );

  MobileMenu.prototype.constructor = MobileMenu;

  MobileMenu.prototype._reset = function() {
    var media = window.matchMedia("(max-width:" + this._maxWidth + "px)");
    var trigger = this._trigger;
    var targets = this._targets;

    if ( media.matches && this._isThrottled ) {
      this._isThrottled = false;
    }

    if ( !media.matches && !this._isThrottled ) {

      trigger.classList.remove("is-active");

      for ( var i = 0; i < targets.length; i++ ) {
        targets[i].removeAttribute("style");
      }

      this._isThrottled = true;
    }
  };

  MobileMenu.prototype.listen = function() {
    var trigger = this._trigger;
    var targets = this._targets;

    trigger.onclick = function() {
      this._handle( trigger, targets );
    }.bind(this);

    window.addEventListener( "resize", this._reset.bind(this) );
  };

  var menu = new MobileMenu({
    trigger: ".js-mobile-menu-trigger",
    target: ".js-mobile-menu-target",
    maxWidth: 1230
  });

  menu.listen();

})();