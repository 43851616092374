window.lsGalleryOwner = 'classicnyprintsgallery';

var api = {
    orders: "/api/orders",
    profile: "/api/auth/users/current",
    subscribeMail: "/api/email/subscribe",
    logOut: "/api/auth/logout"
}

var appState = {
    isLogined: false
};

var text = {
    signUp: 'SIGN UP',
    logIn: 'LOG IN',
    profile: 'MY ACCOUNT',
    logOut: 'LOG OUT'
}

var uri = {
    profile: "/account",
    signUp: "/sign-up",
    logIn: "/login",
    logOut: "/logout"
}

function makeErrorHander(message, callback) {
    return function errorHandler(err) {
        console.error(message, err);
        if (callback) callback(err);
    }
}


!(function() {
    try {
        function showCartItemNumber() {
            fetch(api.orders, {
                credentials: 'include'
            }).then(function(r) {
                return r.json();
            }).then(function(data) {
                var pcount = 0;
                if (data && data.length && data.length > 0) {
                    var p = data[0]["print-groups"];
                    pcount = (p && p.length) || 0;
                }
                console.log('pcount', pcount);
                var countNode = document.querySelector('#cart-items-count');
                if (countNode) {
                    countNode.innerHTML = pcount;
                    countNode.setAttribute('style', 'display:' + (pcount === 0 ? 'none' : 'block'));
                }
            }).catch(makeErrorHander('failed to fetch' + api.orders));
        }

        function addSubscribeHandler() {
            var subscribeForm = document.querySelector('form#email-subscribe');
            var input = subscribeForm.querySelector('input');
            if (subscribeForm && input) {
                subscribeForm.addEventListener('submit', function(e) {
                    e.preventDefault();
                    if (input && input.value) {
                        var span = document.querySelector('span#fetch-response');
                        if (!span) {
                            span = document.createElement('span');
                            subscribeForm.appendChild(span);
                        }
                        fetch(api.subscribeMail, {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                email: input.value,
                                'gallery-user': window.lsGalleryOwner
                            })
                        }).then(function(r) {
                            console.log('email subscribe successfully');
                            if (r.ok) {
                                subscribeForm.innerHTML = '<span>THANK YOU</span>';
                                setTimeout(function() {
                                    subscribeForm.innerHTML = '';
                                }, 3000);
                            } else {
                                span.innerHTML = 'Failed to submit';
                            }
                        }).catch(function(err) {
                            span.innerHTML = 'Failed to submit';
                        })
                    }
                }, false)
            }
        }

        function addSearchHandler() {
            document.querySelectorAll('form.header-search').forEach(function (subscribeForm) {
                var input = subscribeForm.querySelector('input');
                var searchIcon = subscribeForm.querySelector('button.header-search-button');
                if (subscribeForm && input) {
                    subscribeForm.addEventListener('submit', function(e) {
                        e.preventDefault();
                        if (input && input.value) {
                            window.location.href = '/search/' + input.value;
                        }
                    }, false);

                    searchIcon.addEventListener('click', function(e) {
                        e.preventDefault();
                        var currentExpanded = JSON.parse(subscribeForm.getAttribute('aria-expanded'));
                        subscribeForm.setAttribute('aria-expanded', !currentExpanded);
                    }, false);
                }
            })
        }

        function renderProfile() {
            var navSignUpLink = document.querySelector('#nav-sign-up');
            var navLogInLink = document.querySelector('#nav-log-in');
            navSignUpLink.setAttribute('href', appState.isLogined ? uri.profile : uri.signUp);
            navSignUpLink.innerHTML = appState.isLogined ? text.profile : text.signUp;
            navLogInLink.setAttribute('href', appState.isLogined ? uri.logOut : uri.logIn);
            navLogInLink.innerHTML = appState.isLogined ? text.logOut : text.logIn;
            var eventHandler = function(e) {
                e.preventDefault();
                if (appState.isLogined)
                    logout();
                else
                    window.location.href = uri.logIn;
            }
            if (appState.isLogined) {
                navLogInLink.addEventListener('click', eventHandler);
            } else {
                navLogInLink.removeEventListener('click', eventHandler);
            }
        }

        function refreshProfile() {
            fetch(api.profile, {credentials: 'include'})
            .then(function(r) {
                return r.json();
            })
            .then(function(data) {
                appState.isLogined = true;
                appState.profile = data;
                renderProfile();
            })
            .catch(function(err) {
                console.log('Failed to fetch ' + api.profile);
                renderProfile();
            });
        }

        function logout() {
            function afterLogout() {
                appState.isLogined = false;
                appState.profile = null;
                renderProfile();

            }
            fetch(api.logOut, {credential: 'include', method: 'POST'})
            .then(function(r) {
                afterLogout();
            })
            .catch(makeErrorHander('failed to fetch ' + api.logOut, afterLogout));
        }

        function addLogInOutHandler() {
            var navLogInLink = document.querySelector('#nav-log-in');
            navLogInLink.addEventListener('click', function(e) {
                e.preventDefault();
                if (appState.isLogined)
                    logout();
                else
                    window.location.href = uri.logIn;
            });
        }

        //refreshProfile();
        //addLogInOutHandler();

        showCartItemNumber();

        addSearchHandler();

        addSubscribeHandler();
    } catch (e) {
        console.log('LS: caught unhandled exception' + e);
    } finally {
        console.log('LS started!');
    }
})();
